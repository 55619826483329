import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: `${process.env.VUE_APP_IMPORTER_URL}`,
  headers: {
    'Content-type': 'application/json',
    Accept: 'application/json',
  },
});

axiosInstance.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

// axiosInstance.interceptors.request.use(
//   (config) => {
//     const token = localStorage.getItem('access_token');
//     if (token) {
//       // eslint-disable-next-line no-param-reassign
//       config.headers.Authorization = `Bearer ${token}`;
//     }
//     return config;
//   },
//   (error) => {
//     Promise.reject(error);
//   },
// );

export default axiosInstance;
